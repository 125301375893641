import { apiv1 } from "@/request/apiv1";
import qs from "qs";

// 签约分析
export function GetClientsStatisticsSign(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/statistics/sign/pivot?${qs.stringify(params,
            { arrayFormat: "repeat" }
        )}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


export function GetClientsStatisticsSignSummary(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/statistics/sign/summary?${qs.stringify(params,
            { arrayFormat: "repeat" }
        )}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}