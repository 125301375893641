<template>
  <section class="base-page">
    <PageHeader class="base-shadow"></PageHeader>
    <keep-alive><FilterSelection @change="onFilterChange" /></keep-alive>
    <basic-table
      hiddenClear
      hiddenPage
      tableHeight="700px"
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onRefresh="onRefresh"
    >
      <el-table-column type="index" width="50"> </el-table-column>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot="header">
            <span v-if="column.prop === 'signRate'">
              <el-tooltip content="签约数量/跟进客户总量" placement="top">
                <i class="el-icon-info"></i>
              </el-tooltip>
              签约转化率
            </span>
          </template>
          <template slot-scope="scope">
            <span v-if="column.prop === 'rate'"
              >{{ (scope.row.rate * 100).toFixed(3) }} %</span
            >
            <span v-else-if="column.prop === 'invitedRate'"
              >{{ (scope.row.invitedRate * 100).toFixed(3) }} %</span
            >
            <span v-else-if="column.prop === 'signRate'"
              >{{ (scope.row.signRate * 100).toFixed(3) }} %</span
            >
            <span v-else-if="column.prop === 'invitedSignRate'"
              >{{ (scope.row.invitedSignRate * 100).toFixed(3) }} %</span
            >
            <span v-else-if="column.prop === 'clientCount'"
              >{{ scope.row.clientCount }} 个</span
            >
            <span v-else-if="column.prop === 'invitedCount'"
              >{{ scope.row.invitedCount }} 个</span
            >
            <span v-else-if="column.prop === 'signedCount'"
              >{{ scope.row.signedCount }} 个</span
            >
            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <div>
          <span style="padding-right: 10px"
            >跟进客户总量合计：{{ summary.clientCount }} 个</span
          >
          <span style="padding-right: 10px"
            >签约数量合计：{{ summary.signedCount }} 个</span
          >
          <span style="padding-right: 10px"
            >平均转化率：{{ (summary.signRate * 100).toFixed(3) }} %</span
          >
        </div>
      </template>
    </basic-table>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import {
  GetClientsStatisticsSign,
  GetClientsStatisticsSignSummary,
} from "./api";
import { ShowApiError } from "@/request/error";
import { getOrderType } from "@/utils/common";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import FilterSelection from "./components/FilterSelection";
import PageHeader from "@/components/PageHeader";
import config from "@/config";
import { ReadLocalColumn } from "@/utils/table";
import { GetTableFormatParams } from "@/utils/table";
export default {
  components: {
    BasicTable,
    FilterSelection,
    PageHeader,
  },
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      deleteLoading: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "departmentId",
      orderDirection: "descending",
      currentId: "",
      multipleSelected: [],
      orderPropObj: {
        // ownerIds: "owner.id",
        // departmentIds: "owner.department.id",
      },
      summary: {
        clientCount: 0,
        signRate: 0,
        signedCount: 0,
      },
    };
  },
  
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "departmentName",
            label: "部门",
            showColumn: true,
            minWidth: "120px",
            align: "left",
            sortable: true,
          },
          {
            prop: "ownerName",
            label: "负责人",
            showColumn: true,
            minWidth: "120px",
            sortable: true,
          },

          {
            prop: "clientCount",
            label: "跟进客户总量",
            showColumn: true,
            minWidth: "140px",
            align: "left",
            sortable: true,
          },
          {
            prop: "signedCount",
            label: "签约数量",
            showColumn: true,
            minWidth: "120px",
            sortable: true,
          },

          {
            prop: "signRate",
            label: "签约转化率",
            showColumn: true,
            minWidth: "150px",
            sortable: true,
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns);
      }
    },
    filterParams() {
      return GetTableFormatParams(this.orderPropObj, this.tableFilters);
    },
    getTableOrderParams() {
      let orderPropName = "";
      if (this.orderProp !== "" && this.orderDirection !== null) {
        orderPropName = Object.prototype.hasOwnProperty.call(
          this.orderPropObj,
          this.orderProp
        )
          ? this.orderPropObj[this.orderProp]
          : this.orderProp;
        return `${orderPropName},${getOrderType(this.orderDirection)}`;
      } else {
        return null;
      }
    },
    getTableData() {
      this.tableLoading = true;
      this.getSummary();
      GetClientsStatisticsSign({
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          this.tableData = res.data;
          this.total = res.data.length;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },

    getSummary() {
      GetClientsStatisticsSignSummary({
        ...this.filterParams(),
      })
        .then((res) => {
          for (const key in res.data) {
            if (Object.hasOwnProperty.call(this.summary, key)) {
              this.summary[key] = res.data[key];
            }
          }
        })
        .catch((err) => {
          ShowApiError("拉取汇总数错误", err);
        });
    },

    onRefresh() {
      this.page = 0;
      this.getTableData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.onRefresh();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>